import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  large: {
    height: '60px',
  },
  medium: {
    height: '40px',
  },
  small: {
    height: '16px',
  },
});

interface DivisionProps {
  size?: 'small' | 'medium' | 'large';
}

export default function Division({size = 'medium'}: DivisionProps) {
  const classes = useStyles();

  return <div className={classes[size]} />;
}
