import React from 'react';
import {stringifyUrl} from 'query-string';
import {makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import {AiOutlineTwitter} from 'react-icons/ai';
import {formatShortCount} from '@/common/utils/common';

const useStyles = makeStyles(theme => ({
  twitterFollow: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 6px',
    fontSize: '11px',
    fontWeight: 'bold',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  twitterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  twitterFollowIcon: {
    marginRight: '4px',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: '3px',
    borderRadius: '4px',
    marginLeft: '8px',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      position: 'absolute',
      borderTop: '6px solid transparent',
      borderBottom: '6px solid transparent',
      borderRight: '6px solid white',
      left: '-6px',
      top: '4px',
    },
  },
}));

interface CustomTwitterFollowProps {
  handle: string;
  followers?: number;
}

export default function CustomTwitterFollow({
  handle,
  followers,
}: CustomTwitterFollowProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.twitterContainer}>
      {/* <div
        onClick={() => {
          window.open(
            stringifyUrl({
              url: 'https://twitter.com/intent/follow',
              query: {
                screen_name: handle,
              },
            }),
          );
        }}
        className={classes.twitterFollow}
      >
        <AiOutlineTwitter
          className={classes.twitterFollowIcon}
          color={theme.palette.common.white}
          size={14}
        />
        Follow @{handle}
      </div>
      {Boolean(followers) && (
        <div className={classes.tooltip}>
          {formatShortCount(followers!, isDesktop ? ' followers' : '')}
        </div>
      )} */}
    </div>
  );
}
