import {Box, makeStyles, Typography} from '@material-ui/core';
import {Popover, CircularProgress} from '@material-ui/core';
import React from 'react';
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import useLoading from '@/common/hooks/useLoading';
import {AiFillInfoCircle} from 'react-icons/ai';
import theme from '../theme';

interface TwitterProfileEmbedProps {
  id: string;
  twitterHandle: string;
  render: (
    openEmbed: (event: React.MouseEvent) => void,
    isOpen: boolean,
    hasLoaded: boolean,
  ) => React.ReactNode;
}

export default function TwitterProfileEmbed({
  id,
  twitterHandle,
  render,
}: TwitterProfileEmbedProps) {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [_loaded, setLoaded] = React.useState<boolean>(false);
  const hasLoaded = useLoading(_loaded);

  const handlePopoverOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setLoaded(false);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      {render(handlePopoverOpen, isOpen, hasLoaded)}
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        {!hasLoaded && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="300px"
            height="100px"
          >
            <CircularProgress />
          </Box>
        )}
        <div style={{display: hasLoaded ? 'block' : 'none'}}>
          <TwitterTimelineEmbed
            onLoad={() => setLoaded(true)}
            sourceType="profile"
            screenName={twitterHandle}
            options={{height: 400}}
          />
        </div>
      </Popover>
    </>
  );
}

const useStyles = makeStyles(_theme => ({
  profileEmbed: {
    textDecoration: 'underline',
    color: _theme.palette.primary.main,
  },
  profileEmbedContainer: {
    cursor: 'pointer',
  },
}));

interface PreviewTwitterProfileEmbedProps {
  id: string;
  twitterHandle: string;
  label: string;
}

export function PreviewTwitterProfileEmbed({
  id,
  label,
  twitterHandle,
}: PreviewTwitterProfileEmbedProps) {
  const classes = useStyles();

  return (
    <TwitterProfileEmbed
      id={id}
      twitterHandle={twitterHandle}
      render={(openEmbed, isOpen) => (
        <Box
          className={classes.profileEmbedContainer}
          marginBottom={1}
          marginTop={1}
          display="flex"
          alignItems="center"
          onClick={openEmbed}
          // onMouseEnter={openEmbed}
        >
          <AiFillInfoCircle size={18} color={theme.palette.primary.main} />
          <Box marginLeft={1}>
            <Typography
              className={classes.profileEmbed}
              aria-owns={isOpen ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
            >
              {label}
            </Typography>
          </Box>
        </Box>
      )}
    />
  );
}
