import * as Sentry from '@sentry/nextjs';
import React from 'react';
import {Typography, Box} from '@material-ui/core';
import {Query, useMutation, useQueryClient} from 'react-query';
import {queries} from '@/common/api/operations';
import {SimpleAuthDialog} from '@/common/components/SimpleAuthDialog';
import {AiOutlineStar} from 'react-icons/ai';
import {AiFillStar} from 'react-icons/ai';
import {useTheme} from '@material-ui/core';
import {Rating} from '@material-ui/lab';
import {
  HashtagsAndListingsResponse,
  ListingReviewResponse,
  ListingReviewVars,
  ListingsSearchResponse,
  MenuItemsResponse,
  PopularListingsPaginationResponse,
} from '@/common/api/types';
import {useSnackbar} from 'notistack';
import {useApiContext} from '@/common/contexts/ApiContext';
import {transformItemInCache, updateQueryCache} from '@/common/utils/cache';
import { ApiError } from 'next/dist/server/api-utils';

function transformListing(
  listing: ListingsSearchResponse,
  reviewResponse: ListingReviewResponse,
): ListingsSearchResponse {
  console.log('currentUserRating', listing.currentUserRating);
  return {
    ...listing,
    currentUserRating: reviewResponse.rating,
    hasCurrentUserReviewed: Boolean(reviewResponse.rating),
    reviewCount: listing.hasCurrentUserReviewed
      ? listing.reviewCount
      : listing.reviewCount + 1,
    reviewRating: listing.hasCurrentUserReviewed
      ? (listing.reviewRating * listing.reviewCount -
          listing.currentUserRating +
          reviewResponse.rating) /
        listing.reviewCount
      : (listing.reviewRating * listing.reviewCount + reviewResponse.rating) /
        (listing.reviewCount + 1),
  };
}

interface UseRatingFormParams {
  listingId: number | null;
  setListingId: (listingId: number | null) => void;
  onRatedListing: ListingRatingDialogProps['onRatedListing'];
}

function useRatingForm({
  setListingId,
  listingId,
  onRatedListing,
}: UseRatingFormParams) {
  const [rating, setRating] = React.useState<number | null>(null);
  const {apiClient} = useApiContext();
  const rateMutation = useMutation<
    ListingReviewResponse,
    ApiError | Error,
    ListingReviewVars
  >(apiClient.reviews.rateListing, {
    onSuccess,
    onError,
  });
  const {enqueueSnackbar} = useSnackbar();
  const queryClient = useQueryClient();

  function onSuccess(item: ListingReviewResponse) {
    updateQueryCache<HashtagsAndListingsResponse>({
      queryClient,
      queryName: queries.search.hashtagsAndListings,
      getNewCache: transformItemInCache<
        HashtagsAndListingsResponse,
        HashtagsAndListingsResponse['listings'][number]
      >('listings', item.listingId, match => transformListing(match, item)),
    });
    updateQueryCache<MenuItemsResponse>({
      queryClient,
      queryName: queries.search.menuItems,
      getNewCache: (
        cache: MenuItemsResponse,
        query: Query<MenuItemsResponse>,
        allQueries: Query<MenuItemsResponse>[],
      ): MenuItemsResponse | null => {
        return {
          ...cache,
          menuItems: cache.menuItems.map(menuItem => ({
            ...menuItem,
            ...(menuItem.topListings && {
              topListings: menuItem.topListings.map(listing => {
                if (listing.id !== item.listingId) {
                  return listing;
                }

                return transformListing(listing, item);
              }),
            }),
          })),
        };
      },
    });
    updateQueryCache<PopularListingsPaginationResponse>({
      queryClient,
      queryName: queries.search.popularListingsPagination,
      getNewCache: (
        cache: PopularListingsPaginationResponse,
        query: Query<PopularListingsPaginationResponse>,
        allQueries: Query<PopularListingsPaginationResponse>[],
      ): PopularListingsPaginationResponse | null => {
        return {
          ...cache,
          newListings: cache.newListings.map(newListing => {
            if (newListing.id !== item.listingId) {
              return newListing;
            }

            const updatedListing = transformListing(newListing, item);
            onRatedListing?.(updatedListing);
            return updatedListing;
          }),
        };
      },
    });

    setListingId(null);
    setRating(null);
    enqueueSnackbar('Your rating was submitted', {variant: 'success'});
  }

  function onError(error: ApiError | Error) {
    enqueueSnackbar(error.message, {variant: 'error'});
    Sentry.captureException(error);
  }

  const handleDismissListing = () => {
    setListingId(null);

    if (rating !== null) {
      setRating(null);
    }
  };
  const handleConfirmListing = () => {
    if (rating === null) {
      const message = 'Please select a rating';
      enqueueSnackbar(message, {variant: 'error'});
      Sentry.captureMessage(message);
      Sentry.captureException(new Error(message));
      return;
    }

    if (listingId === null) {
      const message = 'Please select a listing';
      enqueueSnackbar(message, {variant: 'error'});
      Sentry.captureMessage(message);
      Sentry.captureException(new Error(message));
      return;
    }

    rateMutation.mutate({listingId, rating});
  };
  const onChangeRating = (e: React.ChangeEvent<{}>, value: number | null) => {
    setRating(value);
  };

  return {
    rating,
    onChangeRating,
    handleDismissListing,
    handleConfirmListing,
  };
}

interface ListingRatingDialogProps {
  isOpen: boolean;
  listingName?: string;
  listingId: number | null;
  setListingId: (listingId: number | null) => void;
  onRatedListing?: (listing: ListingsSearchResponse) => void;
  hasCurrentUserReviewed?: boolean;
}

export default function ListingRatingDialog({
  isOpen,
  listingId,
  listingName,
  setListingId,
  onRatedListing,
  hasCurrentUserReviewed,
}: ListingRatingDialogProps) {
  const {rating, onChangeRating, handleDismissListing, handleConfirmListing} =
    useRatingForm({setListingId, listingId, onRatedListing});
  const theme = useTheme();

  return (
    <SimpleAuthDialog
      id="rate-dialog"
      title="Thank you for your review"
      isOpen={isOpen}
      onCancel={handleDismissListing}
      onConfirm={handleConfirmListing}
    >
      {hasCurrentUserReviewed ? (
        <Box>
          <Typography>
            You have already made a review to{' '}
            <b>{listingName || 'this listing'}</b>.
          </Typography>
          <br />
          <Typography>Do you want to add a new review?</Typography>
        </Box>
      ) : (
        <Box>
          <Typography>
            How many stars would you give to{' '}
            <b>{listingName || 'this listing'}</b>?
          </Typography>
        </Box>
      )}

      <Box marginTop={2}>
        <Rating
          name="listing-rating"
          size="large"
          value={rating}
          onChange={onChangeRating}
          precision={1}
          icon={
            <AiFillStar color={theme.palette.primary.main} fontSize="inherit" />
          }
          emptyIcon={<AiOutlineStar fontSize="inherit" />}
        />
      </Box>
    </SimpleAuthDialog>
  );
}
