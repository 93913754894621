import { Typography, makeStyles } from '@material-ui/core';
import Link from 'next/link';
import React from 'react';

interface CustomLinkProps {
  href?: string;
  text?: string;
  isExternal?: boolean;
}

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export default function CustomLink({
  text,
  href = '',
  isExternal = false,
}: CustomLinkProps) {
  const classes = useStyles();

  return isExternal ? (
    <a href={href} target="_blank" rel="noreferrer">
      <Typography variant="body1" className={classes.link}>
        {text}
      </Typography>
    </a>
  ) : (
    <Link href={href}>
      <Typography variant="body1" className={classes.link}>
        {text}
      </Typography>
    </Link>
  );
}
