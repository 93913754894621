import {Box, Grid, makeStyles, Paper, Typography} from '@material-ui/core';
import React from 'react';
import {QueryClient, useQuery} from 'react-query';
import {queries} from '../api/operations';
import {appRoutes, flags, resolveImageUrl} from '../config';
import {ApiClient, useApiContext} from '../contexts/ApiContext';
import {formatShortCount} from '../utils/common';
// import CustomLink from './CustomLink';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp, library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import Tooltip from "@material-ui/core/Tooltip";
import { RiInformationLine } from 'react-icons/ri';
import {useRouter} from 'next/router';

library.add(fas, far);

const useStyles = makeStyles(theme => ({
  thumbnail: {
    width: '32px',
    height: '32px',
  },
  tooltip: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 'bold',
    padding: '3px',
    borderRadius: '4px',
    marginLeft: '8px',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      position: 'absolute',
      borderTopWidth: '6px',
      borderTopStyle: 'solid',
      borderTopColor: 'transparent',
      borderBottomWidth: '6px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'transparent',
      borderRightWidth: '6px',
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.primary.main,
      left: '-6px',
      top: '4px',
    },
  },}));

export async function ssrBadges(
  queryClient: QueryClient,
  apiClient: ApiClient,
) {
  await queryClient.prefetchQuery(queries.listings.getBadges, () =>
    apiClient.badges.pagination({
      skipCount: 0,
      pageSize: 100,
      orderAsc: true,
      includeTotalListings: true,
    }),
  );
}

function useBadges() {
  const {apiClient} = useApiContext();
  const getBadgesQuery = useQuery(queries.listings.getBadges, () =>
    apiClient.badges.pagination({
      skipCount: 0,
      pageSize: 100,
      orderAsc: true,
      includeTotalListings: true,
    }),
  );
  const badges = getBadgesQuery.data?.badges ?? [];

  return {badges};
}

export function BadgeBreakdown(): React.ReactElement {
  const {push} = useRouter();
  const {badges} = useBadges();
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" marginTop={1} marginBottom={1}>
        <Typography variant="h6">Badges</Typography>
        <div>
          <Tooltip title={<h1 style={{ color: 'primary', fontSize: 16, lineHeight: 1.5 }}>Badges indicate a verified accomplishemnt for the associated listing. A listing with the Bestseller badge, for example, has been verified to have met our criteria as a bestseller.</h1>}>
            <Box  marginLeft={1}>
              <RiInformationLine color='primary' size={24} />
            </Box>
          </Tooltip>
        </div>
      </Box>
      <Grid container spacing={1}>
        {badges.map(badge => (
          <Grid key={badge.id} item>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{cursor: 'pointer'}}
              onClick={() => push(appRoutes.home.badges(`${badge.name}`))
            }>
              <Paper>
                <Box
                  display="flex"
                  alignItems="center"
                  marginLeft={1}
                  marginRight={1}
                  padding={1}
                >
                  {flags.ENABLE_BADGE_IMAGE && badge.imageId && (
                    <img
                      title={badge.name}
                      src={resolveImageUrl(badge.imageId)}
                      className={classes.thumbnail}
                    />
                  )}
                  {flags.ENABLE_BADGE_ICON && badge.iconName && (
                    <FontAwesomeIcon
                      size="2x"
                      color={badge.iconColor}
                      icon={badge.iconName as IconProp}
                    />
                  )}
                  <Box marginLeft={1}>
                    <Typography color="primary" >{badge.name}</Typography>
                   </Box>
                </Box>
              </Paper>
              {badge.totalListings !== undefined && badge.totalListings > 0 && (
                <div className={classes.tooltip}>
                  {formatShortCount(Math.round(badge.totalListings), '')}
                </div>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box marginBottom={3} />
    </>
  );
}
