import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingButtonProps {
  loading: boolean;
  children: string;
  onClick: any;
}

const LoadingButton = ({
  loading = false,
  children,
  onClick,
}: LoadingButtonProps) => {
  if (loading) {
    return (
      <Button
        variant="outlined"
        onClick={onClick}
        disabled
        startIcon={<CircularProgress size={14} />}
      >
        {children}
      </Button>
    );
  } else {
    return (
      <Button variant="outlined" onClick={onClick}>
        {children}
      </Button>
    );
  }
};

export default LoadingButton;
