import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export interface SimpleDialogProps {
  id: string;
  title?: string;
  isOpen: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  children: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
}

export function SimpleDialog({
  id,
  title,
  isOpen,
  onCancel,
  onConfirm,
  children,
  disabled = false,
  fullWidth = false,
  cancelLabel = 'Cancel',
  confirmLabel = 'Submit',
}: SimpleDialogProps) {
  return (
    <Dialog
      fullWidth={fullWidth}
      // maxWidth={maxWidth}
      open={isOpen}
      onClose={onCancel}
      aria-labelledby={id}
    >
      {title && <DialogTitle id={id}>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onConfirm && (
          <Button onClick={onConfirm} disabled={disabled} color="primary">
            {confirmLabel}
          </Button>
        )}
        {onCancel && (
          <Button onClick={onCancel} color="secondary">
            {cancelLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
